



@media screen and (min-width: 320px) {
    html {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 1rem;

    }
}

.text-gradient {
    background: -webkit-linear-gradient(270deg, $secondary 20%, $compliment 70%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}


h1 {
    @include text-xl;
    // @extend .text-gradient;
}

h2,h3 {
    color: $offblack
}

h2 {
     @include text-lg
}

h3 {
     @include text-md
}