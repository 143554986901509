@mixin fullwidthbgimg {

    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }
@mixin seriffonts {

    font-family:Georgia, 'Times New Roman', Times, serif
  }

 @mixin blur($radius:5px) {
  -webkit-filter: blur($radius);
  -moz-filter: blur($radius);
  -o-filter: blur($radius);
  -ms-filter: blur($radius);
  filter: blur($radius);
}
 @mixin textshadow ($shadcolour:fadeout(#2f3b51,20%)) {
  text-shadow: 1px 1px 2px $shadcolour;
}

 @mixin text-sm {
  font-size: 14px;
}
 @mixin text-md {
  font-size: 110%;
}
 @mixin text-lg {
  font-size: 120%;
}
 @mixin text-xl {
  font-size: 200%;
}

 @mixin fa {
 font-family: "bootstrap-icons" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;


}
.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin radius($radius:5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
 @mixin shadow($shadow:2px) {
  -webkit-box-shadow: 0px $shadow $shadow 0px rgba(50, 50, 50, 0.6);
  -moz-box-shadow: 0px $shadow $shadow 0px rgba(50, 50, 50, 0.6);
  box-shadow: 0px $shadow $shadow 0px rgba(50, 50, 50, 0.6);

  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

 @mixin wellclr ($wellclr) {
  background-color: $wellclr;
}
 @mixin panel {
  background-color: $cream;
  color: darken($cream, 60%);
  a {
    color: $primary;
    &:hover {
      color: $primary;
    }
  }
}

 @mixin gradient($gradcolour) {
  $gradtop: lighten($gradcolour, 20%);
  $gradbtm: darken($gradcolour, 0%);
  background: $gradtop; /* Old browsers */

  background: -moz-linear-gradient(
    top,
    $gradtop 0%,
    $gradbtm 100%
  ); /* FF3.6+ */

  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $gradtop),
    color-stop(100%, $gradbtm)
  ); /* Chrome,Safari4+ */

  background: -webkit-linear-gradient(
    top,
    $gradtop 0%,
    $gradbtm 100%
  ); /* Chrome10+,Safari5.1+ */

  background: -o-linear-gradient(
    top,
    $gradtop 0%,
    $gradbtm 100%
  ); /* Opera 11.10+ */

  background: -ms-linear-gradient(top, $gradtop 0%, $gradbtm 100%); /* IE10+ */

  background: linear-gradient(to bottom, $gradtop 0%, $gradbtm 100%); /* W3C */
}
