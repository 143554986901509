div.overlay,
.modal {
    transition: all 0s ease;
    // background-image: url("/images/p4.png");
    background-color: rgba($offblack, 0.5);
    backdrop-filter: blur(4px);
}

div.overlay.active {
    transition-delay: 0.25s;
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    display: block;
    height: 100%;
    z-index: 2;
}

//.megacontent { z-index: 3}
#StrappingLoadSecuring .megacontent {
        // left: 0px !important;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // transition-delay: 0.05s;
}
#SecuritySeals .megacontent {
    // left: 0px !important;
}
#StainlessSteelStrapping .megacontent {
    // left: 0;
    // right: 0;
    // margin: 0 auto;

}
.megamenu {
    z-index: 2;
    li {


        a{
            font-size: 1rem;
            color: $white;
            padding: 10px ;
            position: relative;

            &:hover {
                color: $white !important;
                text-decoration: none;
                background-color: rgba($white, 0.1);
            }

            &.active {
                color: lighten($primary, 10%);
            }
        }

        &.toplevel{
            &:hover > a,
            &.current > a {
                color: $secondary !important;
                background-color: white !important;
                border: none;
            }}

        //Hover Arrows
        // &.toplevel {
        //     &:hover > a {
        //         &:after {
        //             bottom: -15px;
        //             z-index: 1050;
        //             left: 50%;
        //             border: solid transparent;
        //             content: " ";
        //             height: 0;
        //             width: 0;
        //             position: absolute;
        //             pointer-events: none;

        //             border-top-color: $secondary;
        //             border-width: 10px;
        //             margin-left: -10px;
        //         }
        //     }
        // }

        // &.toplevel.current a {
        //     background-color: rgba($white, 0.1);
        // }

        .megacontent {
            left: -999em;
            transition: all 0s ease;
            z-index: 3;

            position: absolute !important;
            top: 45px;



            .colsep {
                margin: 5px 0px !important;
                float: left;
                width: 100%;
            }

            ul {
                padding: 0px;
                list-style: none;
            }

            li {
                span.nav-header a,
                span.nav-header {
                    color: $secondary !important;
                    text-transform: uppercase;

                    width: 100%;
                    display: block;
                    font-weight: 700;

                }
            }

            a {
                font-size: 1rem;

                padding: 2px 0px !important;

                .menusuffix {
                    font-weight: 700 !important;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            a {
                color: darken($darkgrey, 5%) !important;
                padding: 2px 0px !important;
                font-weight: 300;
                font-size: 0.9rem;

                .menusuffix {
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .faqwrap{
                ul {padding-left: 0px !important;
                li{ list-style: none;

                    &:hover { background-color: rgba($compliment,0.6) !important;}

                a { color: $white !important; font-size: 0.9rem;  &:hover { text-decoration: none; background-color: transparent !important;}}

                }

            // li:before {
            //  margin-right: 5px;
            //  margin-top: 2px;
            //  position: relative;
            //  top: 2px;
            //  font-family: bootstrap-icons;
            //      content: '\F287';
            //      color: $white;
            //      font-size: 0.5rem;
            //    }
            }}

            .btn-brands {
                font-size: 200% !important;
                color: $secondary;
                border: 2px $primary solid;
                padding: 10px !important;
                display: inline !important;
            }

            .megafooter {
                background-color: $compliment;
                color: $highlight;
                a{color: $highlight !important;}

                small {
                    color: rgba(#fff, 0.6);
                    font-size: 0.8rem;
                    font-style: italic;
                }

                strong {
                    font-weight: 700;
                }
            }
        }
    }

    li:hover {
        .megacontent {
            // left: 0;
            // right: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transition-delay: 0.05s;


        }
    }


//     .megacontent {
//         &:before{ content: ''; background-color: red; width: auto; height: 100%; }

// }

}
// .megacontent:has(.innerwrap){
//     .innerwrap:hover{
//             left: -999em !important;

// }
// }




//#Coffee .megacontent{ background-image: url("/images/menubg/megabg-coffee.jpg")}

//  #Strapping .megacontent  { left: 0px !important}

li.col {
    ul {
        margin-bottom: 10px;
    }
}

//#Sale .megacontent{ left: 0px  !important;}
#Cups .megacontent {
    li {
        a {
            font-size: 70% !important;

            img {
                width: 80px !important;
            }
        }
    }
}
