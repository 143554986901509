.offcanvas-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}
.offcanvas-footer {
    border-width: 0;
    display: flex;
    justify-content: flex-start;
    padding: 1.5rem 2rem;
}
:not(.toast-header):not(.alert)>.btn-close {
    display: inline-flex;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 1030;
}
#modalShoppingCartContent {


}

.offcanvas {
    overflow-y: auto;



  }
  .offcanvas-body {
    flex-grow: 0;
    overflow: visible;
  }
  .offcanvas-header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e5e5e5;
  }
  .offcanvas-footer {
    bottom: 0px;
    display: flex;
    justify-content: flex-start;
    padding: 1.5rem 2rem;
    border-width: 0;
  }