
/*----------TRUST PILOT STYLES------------*/



#reviewsModal {
    .modal-header {
        background-color: $white  !important
    }
}

.reviewcarousel em {
    font-size: 120% !important;
}

.tpwrapper {
    color: $offblack;
    font-size: 16px;
    font-weight: 300;
    float: left;
    width: 100%;
    position: relative;
    padding-left: 0px;

    .inner {
        transition: all 0s ease;
        top: -99999px;
        display: block !important;
        /*display: block !important; */
        padding: 5px 15px;

        strong {
            font-size: 14px;
            font-weight: 500;
        }

        ;
        font-size: 14px;
        position: absolute;
        float: left;
        width: 100%;
        z-index: 1;
        border: #00b67a 2px solid;
        @include radius();
        background-color: #FFFFFF;
        @include shadow()
    }

    &:hover {
        .inner {
            top: 30px;
            transition-delay: 0.2s;
        }
    }

}

.proggress {
    width: 0%
}

#trustprogressbar5 .proggress {
    background-color: #50b37e !important;
}

#trustprogressbar4 .proggress {
    background-color: #89cc41 !important;
}

#trustprogressbar3 .proggress {
    background-color: #f7cf46 !important;
}

#trustprogressbar2 .proggress {
    background-color: #f08b3e !important;
}

#trustprogressbar1 .proggress {
    background-color: #ec4b34 !important;
}

.progressbarwrap {
    float: left;
    width: 70% !important;
    display: inline;
    padding-left: 5px;
    padding-right: 5px;

    .progressbar {
        margin-bottom: 10px !important;
    }

}

.progressbarspan {
    float: left;
    display: inline;
    width: 15% !important;
}

.tpprodwrapper {
    background-color: $green
}

.custreviews {
    font-size: 80%
}

/*----------TRUST PILOT STYLES------------*/