.productdetails-view,
.category-view {
    //   .vm-product-media-container a {
    //     &::after {
    //       position: absolute;
    //       content: "";
    //       top: 0;
    //       left: 0;
    //       width: 100%;
    //       height: 100%;
    //       background: rgb(143, 143, 143);
    //       opacity: 0.03;
    //       pointer-events: none;
    //       border: #131313 solid 1px !important ;
    //     }
    //     &:hover::after {
    //       background: rgb(0, 0, 0);
    //     }
    //   }

    #desc,
    #reviews {
        display: block;
        position: relative;
        top: -150px;
        visibility: hidden;
    }
    .fractions {
        font-size: 1rem;
    }

    .prod_catdesc {
        div {
            column-count: 4;
            @media screen and (max-width: 830px) {
                column-count: 1;
            }
        }
        p {
            margin-bottom: 0.5rem;
        }
    }

    .mainproddesc {
        *:empty {
            display: none;
        }
    }

    .product-saving {
        color: darken($green, 10%);
    }
    .spectable {
        
        table {
            @extend .table;
            @extend .table-sm;
            thead {
                @extend .table-dark;
            }
        }
    }

    /*** custom checkboxes ***/

    //   input[type="checkbox"] {
    //     display: none;
    //   }

    //   /* to hide the checkbox itself */
    //   input[type="checkbox"] + label:before {
    //     //font-family: FontAwesome;
    //     @include fa;
    //     display: inline-block;
    //   }

    label strong.price {
        color: $primary;
    }

    //   input[type="checkbox"] + label:before {
    //     content: "\f0c8";
    //     color: lighten($offblack, 20%);
    //   }

    /* unchecked icon */
    input[type="checkbox"] + label:before {
        letter-spacing: 10px;
    }

    /* space between checkbox and label */

    input[type="checkbox"]:checked + label:before {
        content: "\f14a";
        color: $green;
    }

    /* checked icon */
    input[type="checkbox"]:checked + label:before {
        letter-spacing: 10px;
    }

    /* allow space for check mark */

    .full-width {
        background-color: #ffffff;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        h2 {
            font-size: 200%;
            font-weight: 100;
            color: rgba($offblack, 0.7);
        }

        h3 {
            font-size: 80%;
            font-weight: 600;
        }
    }

    p:empty {
        display: none;
    }

    .border-dark {
        border-color: rgba($offblack, 0.3) !important;
    }
    .spectable {
        font-size: 0.8rem;
        p {
            margin-bottom: 0px;
        }
    }
    .tags {
        font-size: 1.2rem;
    }
    .text-muted {
        // color: rgba($offblack, 0.8) !important;

        @media screen and (max-width: 830px) {
            font-size: 60%;
        }
    }

    .title {
        background-color: $offblack;

        h2 {
            color: $white !important;
            font-weight: 100;
        }
    }

    .specs {
        background-color: #64594f;
        color: rgba($white, 0.9) !important;

        h2 {
            font-weight: 100;
            color: $white;

            span {
                color: $primary;
            }
        }
    }

    .youtube {
        background-color: $primary;
        color: rgba($white, 0.9) !important;

        h4 {
            color: $primary;
        }
    }

    .reviews {
        background-color: $cream;
        background-image: url("/images/fullwidthbg/reviews-bg.jpg");
        color: $offblack;

        h2 {
            font-weight: 600;
        }
    }

    .reviewsfull {
        background-color: $white;
        color: $offblack;
    }

    .relcarousel {
        background-color: $white;
        color: $offblack;
    }

    .cups {
        background-color: rgba($offblack, 0.1);
        color: $offblack;
        text-transform: uppercase;
        font-weight: 100;

        h2 {
            color: rgba($offblack, 0.7) !important;
        }

        ul {
            list-style: none;

            .col {
                z-index: 1;
            }

            @media screen and (max-width: 830px) {
                padding: 0px;
            }
        }

        &:before {
            content: "";
            float: left;
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 43%;
            z-index: -1;
            background-color: rgba($offblack, 0.1);

            @media screen and (max-width: 830px) {
                display: none;
            }
        }
    }

    .description {
        color: $offblack;
    }

    .topdescwrapper {
        ul {
            display: none !important;
        }

        font-size: 80%;

        p {
            margin-bottom: 2px;
        }
    }

    .table-sm {
        font-size: 80%;
    }

    .card {
        .card-header {
            font-size: 80%;
            background-color: transparent;
        }
    }

    .input-group-text {
        background-color: $cream;
    }

    .countdownwrap {
        @include panel;

        #countdown {
            @include text-md;
        }

        .smalltext {
            @include text-sm;
        }

        .icon i {
            font-size: 42px;
        }

        #gmctime-countdown1 {
            color: $primary;
        }
    }
    table.straptitetable {
        td {background-color: $white !important;}


        tr:hover {
            cursor: pointer;
        }
        tr.active td,
        tr.active th {
            background-color: rgba($secondary, 0.2) !important;
        }
    }
    .childprodwrap {
        .item {
            position: relative;
            .rounded.active {
                &:before {
                    top: 0px;
                    z-index: 1050;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;

                    border-top-color: rgba($blue, 0.2);
                    border-width: 10px;
                    margin-left: -10px;
                }
            }
        }
    }
    .smalldesc {
        font-size: 1rem;
        color: darken($cream, 50%);
        padding: 0px;
        margin: 0px;
    }
    ul.bullets {
        font-size: 1rem;
        list-style: none;
        padding-left: 20px;
        li {
            text-indent: -21px;
        }
        li:before {
            margin-right: 5px;
            margin-top: 2px;
            position: relative;
            top: 2px;
            font-family: bootstrap-icons;
            content: "\F633";
            color: $highlight;
        }
    }

    .multibuytable {
        font-size: 70%;

        td,
        th {
            text-align: center;
            padding: 2px;
            border: 0px;
        }

        thead th {
            background-color: $light;
        }
    }

    .table-bordered {
        border-top-right-radius: 15px !important;
        border-top-left-radius: 15px !important;
    }

    //   .childprodwrap {
    //     .item {
    //       .card {
    //         border: $blue 1px solid;
    //       }

    //       .name {
    //         font-size: 90%;
    //       }

    //       .product_price {
    //         color: $primary;
    //       }
    //     }
    //   }
    fieldset {
        border: 1px;
    }
    legend {
        float: none;
        text-align: center;
    }

    .carousel-inner {
        background-color: $cream;
    }

    .product-description-wrap {
        li {
        }
    }

    .compare {
        border: 1px;

        table.comparetable {
            border: none;

            td,
            th {
            }
        }

        table.comparetable thead {
            th {
                border-top: none;
            }
        }

        table.comparetable thead th + th,
        table.comparetable tr td {
            background-color: $cream !important;
        }

        table.comparetable thead th + th + th,
        table.comparetable tr td + td {
            background-color: transparent !important;
        }

        table.comparetable thead th + th {
            border-top: border $primary solid;
            border-left: border $primary solid;
            border-right: border $primary solid;
        }

        table.comparetable tr td {
            border-left: border $primary solid;
            border-right: border $primary solid;
        }

        table.comparetable tr:last-child td {
            border-bottom: border $primary solid;
            padding-bottom: 5px;
        }

        table.comparetable tr:last-child td + td {
            border-bottom: none;
        }

        table.comparetable tr td + td {
            border-left: none;
            border-right: none;
        }

        table.comparetable thead th + th + th {
            border-left: none;
            border-right: none;
            border-top: none;
        }

        .table-condensed {
            td {
                padding: 8px 8px 0px 8px !important;
            }
        }
    }

    h3 {
        color: $secondary;
        font-weight: 500;
        font-size: 110%;
        a {
            color: $secondary;
        }
    }

    .description {
        font-size: 100%;

        color: $offblack;

        h2 {
            color: $primary;
            font-weight: 500;
            font-size: 150%;
        }

        p {
            margin-bottom: 15px;
        }

        table {
            font-size: 90% !important;

            td {
                font-size: 90% !important;

                h5 {
                    font-size: 100% !important;
                    font-weight: 700;
                }
            }
        }

        .whyshopcol {
            font-size: 80%;

            ul {
                margin-bottom: 10px;
                padding: 0px;
                list-style: none;

                li {
                    font-weight: 600;
                    margin-left: 20px;
                }
            }

            h2 {
                color: $primary !important;
                font-size: 120%;
            }
        }

        i,
        svg {
            color: $primary;
        }
    }

    button.btn-addtocart.btn-lg {
    }

    .PricepriceWithoutTaxTt,
    .PricesalesPriceTt.NoVAT {
        font-size: 3rem;
        line-height: 80% !important;
        font-weight: 600;

        @media screen and (max-width: 500px) {
            font-weight: 500;
            font-size: 420%;
            text-align: center !important;
        }
    }

    .pricesmall {
        font-size: 0.8rem;
        color: $grey;
    }

    .additional-images {
        top: 0;
        left: 0;
    }

    .multibuy {
        .saving {
            color: $green;
            font-weight: 500;
        }
    }

    .specs {
        .ribbon {
            position: absolute;
            left: -5px;
            top: -5px;
            z-index: 1;
            overflow: hidden;
            width: 120px;
            height: 120px;
            text-align: right;
        }

        .ribbon span {
            font-size: 120%;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 30px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            width: 150px;
            display: block;
            background: #79a70a;
            background: linear-gradient(#9bc90d 0%, #79a70a 100%);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 28px;
            left: -32px;
        }

        .ribbon span::before {
            content: "";
            position: absolute;
            left: 0px;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #555555;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #555555;
        }

        .ribbon span::after {
            content: "";
            position: absolute;
            right: 0px;
            top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid #555555;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #555555;
        }
    }

    .specsouterwrap {
        column-count: 3;
        column-gap: 0;
        font-size: 80%;

        .specwrap {
            break-inside: avoid-column;
            border-color: rgba($cream, 0.2) !important;

            .spectitle {
                font-weight: 600;
                color: $primary;
                font-size: 110%;
            }

            .spectext {
            }
        }
    }
    .specsouterwrapv2 {
        column-count: 2;
        column-gap: 0;
        font-size: 80%;

        .specwrap {
            break-inside: avoid-column;
            border-color: rgba($cream, 0.2) !important;

            .spectitle {
                font-weight: 600;
                color: $primary;
                font-size: 110%;
            }

            .spectext {
            }
        }
    }

    .nav-tabs .nav-link {
        &.active {
            color: $primary;
        }
    }

    .addtocartbar {
        .quantity-container {
            background-color: $cream;
        }

        // .qtyinput {
        //   border: none;
        //   background-color: transparent;
        //   text-align: center !important;
        //   font-size: 120%;
        //   color: #6c757d;
        // }

        .btn-outline-secondary {
            border-color: #ced4da !important;
        }
    }

    .addcheckbox {
        .pricesmall {
            font-size: 70%;
            line-height: 100% !important;
            display: inline-block;
        }
    }
}

// Nutritional Facts

.performance-facts {
    h3 {
        color: $offblack;
        font-size: 200%;
    }

    // transform: rotate(-2deg);
    font-size: 80%;
    @include radius();
    background-color: $white;
    @include gradient($cream);
    /*position: absolute; right: 20px; top: -30px;*/
    border: 1px solid black;

    /*width: 280px;*/
    padding: 0.5rem;
    z-index: 2;

    table {
        border-collapse: collapse;
    }

    header {
        color: $offblack;
        background-color: $white;
    }
}

.performance-facts__title {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 0 0.25rem 0;
}

.performance-facts__header {
    border-bottom: 10px solid black;
    padding: 0 0 0.25rem 0;
    margin: 0 0 0.5rem 0;

    p {
        margin: 0;
    }
}

.performance-facts__table {
    width: 100%;

    thead tr {
        th,
        td {
            border: 0;
        }
    }

    th,
    td {
        font-weight: normal;
        text-align: left;
        padding: 0.25rem 0;
        border-top: 1px solid black;
        white-space: nowrap;
    }

    td {
        &:last-child {
            text-align: right;
        }
    }

    .blank-cell {
        width: 1rem;
        border-top: 0;
    }

    .thick-row {
        th,
        td {
            border-top-width: 5px;
        }
    }
}

.small-info {
    font-size: 0.7rem;
}

.performance-facts__table {
    color: $offblack !important;
    font-size: 80%;
}

.performance-facts__table--small {
    .performance-facts__table {
        color: $offblack;
    }

    border-bottom: 1px solid #999;
    margin: 0 0 0.5rem 0;

    thead {
        tr {
            border-bottom: 1px solid black;
        }
    }

    td {
        &:last-child {
            text-align: left;
        }
    }

    th,
    td {
        border: 0;
        padding: 0;
    }
}

.performance-facts__table--grid .performance-facts__table {
    margin: 0 0 0.5rem 0;

    td {
        &:last-child {
            text-align: left;

            &::before {
                content: "•";
                font-weight: bold;
                margin: 0 0.25rem 0 0;
            }
        }
    }
}
