.straptitetable-alt {
    table {
        // padding: 0.2rem 0.2rem;
        thead {
            tr {
                th {
                    background-color: $blue; /* This sets the background color */
                    font-weight: 300;
                    padding: 15px 10px;
                    text-align: center;
                    vertical-align: middle;
                }
                th:first-child {
                    border-top-left-radius: 10px;  /* Adjusts the top-left corner radius */

                }
                th:last-child {
                    border-top-right-radius: 10px; /* Adjusts the top-right corner radius */

                }
            }
        }
        tbody {
            tr:last-child {
                td,th { border-bottom: none;}
                td:first-child, th:first-child {
                    border-bottom-left-radius: 10px; /* Rounds the bottom-left corner */
                }
                td:last-child, th:last-child {
                    border-bottom-right-radius: 10px; /* Rounds the bottom-right corner */
                }
            }
        }
        td {
            text-align: center;
            vertical-align: middle;
            padding: 0.2rem 0.2rem;
            font-size: 0.8rem;
            @media screen and (min-width: 830px) {
                font-size: 1rem;
            }
        }
        th {
            font-size: 0.8rem;
            @media screen and (min-width: 830px) {
                font-size: 1rem;
            }
            padding: 0.2rem 0.2rem;
            font-weight: 300;
            text-align: center;
            vertical-align: middle;
        }
    }
}
