
.contact-us-wrap {
    color: $white;

    main.container {
        background-color: transparent
    }

    h1 {
        font-size: 300%;
        font-weight: 100;
        color: $white
    }

    h2 {
        font-size: 150%;
        font-weight: 600;
        color: $white;
    }

    a {
        color: $white
    }

    .formContainer {
        .form-group {
            label {
                display: none
            }
        }
    }
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 20%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}