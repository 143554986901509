// @import "../node_modules/bootstrap/scss/../node_modules/bootstrap/scss/..//node_modules/bootstrap/scss/bootstrap.scss";

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

// scss-docs-start import-stack
// Configuration

@import "_2overrides.scss";

@import "../node_modules/bootstrap/scss/bootstrap.scss";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;500;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
