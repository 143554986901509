.topmenu {
  font-size: 14px;

  a {
    color: darken($white, 30%);
    margin-right: 15px;

    &.signin-out-btn {
      color: $white;
      font-weight: 500;
    }
  }
}

a.btn.jmodedit {
  display: none;
}

header {
  background-color: $white;
  color: darken($grey,50%);

  a,.btn, i {
    color: $white;
  }
  .bi { font-size: 2rem;}
  .bi-search {font-size: 1.3rem; }

  /*background-image:url("coffeeheader.jpg");*/
  background-repeat: no-repeat;
  background-size: cover;

  .logobar.container {
    max-width: 1400px;
  }

  .popover-btn {

  }
  .ctabar { font-size: 0.8rem;i{ position: relative; top: 2px;font-size: 1.2rem; }}

  //@include fa;

  .btn-outline-secondary {
    color: $secondary !important;
     background-color: $white;


    &:hover {
      background-color: $secondary;
      border-color: $secondary;
      color: $white !important;
    }
  }

  .form-control .cf-searchmod-wrapper {
    border: 0px;
  }

  .loginbox {
    position: relative;
  }

  .loginfloatbox {
    position: absolute;
    background-color: $white;
    bottom: 0px;
  }

  .btn-popover-container {
    display: inline-block;

    &:hover {
      cursor: pointer;
      text-decoration: underline !important;
      color: $primary;
    }
  }

  .btn-popover-container .btn-popover-title,
  .btn-popover-container .btn-popover-content {
    display: none;
  }

  #login-form {
    label {
      justify-content: flex-end;
    }
  }

  .searchouterwrap {
    background-color: $white;

    #searchresults {
      background-color: #fff;
    }
    .col.product{

        img{ width: 50%; align-self: center;}
//         position: relative;
//         .vm-product-media-container {}
// a{
//     align-items: center !important;
//     display: flex;
//     width: 100%;
//     height: 100%;
//     position: relative;
//     justify-content: center !important;

//         &::after {
//             position: absolute;
//             content: "";
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             background: rgb(143, 143, 143) !important;
//             opacity: 0.08;
//             pointer-events: none;
//             // border: #131313 solid 1px !important ;
//           }
//           &:hover::after {
//             background: rgb(0, 0, 0) !important;
//           }
//     }
}

    @media screen and (max-width: 830px) {
      top: 120px;
    }
  }
}
