.carousel-inner{

    max-height: 500px;
    overflow: hidden;
}

.carousel-item {
    display: flex !important;
    justify-content: center !important; /* Align horizontally */
    align-items: center !important;     /* Align vertically */
    // height: 450px !important;            /* Take full height of the container */
    max-height: 450px;
}
.hero {
    width: 100%; /* Ensure full width */
}