
.leftcol {
    h3 {

        font-weight: 600;
        font-size: 110%;
    }

    ul {
        list-style: none;
        padding: 0px;

        li {
           margin-bottom: 7pmpx;
            list-style: none;
            padding: 0px;


            // &:after {
            //     @include fa;
            //     content: "\f054";
            //     color: $primary;
            //     margin-left: 10px;
            // }

            // &.active {
            //     font-weight: 600;

            //     a {}
            // }

            a {
                line-height: normal;
                color: $secondary  !important;
                font-weight: 500;
            }
        }

    }

    .faqmenu{a{ color: rgba($primary,0.7) !important}
&.active{a{ color: $primary !important; font-weight: 500;}}}
}