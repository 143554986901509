#vmMainPageOPC {
    .expresswrapper {


        table {
            display: flex;
            margin-top: 10px;
            tbody{ align-self: center !important; margin: auto;}

        }
    }
    .vmCartShipmentLogo img {
        width: 30px;
        @extend .me-3;
    }
    h2 {
        letter-spacing: -0.03rem;
        font-weight: 400;
    }
    .opc_errors {
        border: 0px !important;
        color: $white;
        background-color: $orange;
        margin-top: 10px;
        padding: 10px 30px;

        .row {
            display: block;
            padding: 0px !important;
        }
    }

    .multistepwrap {
        position: relative;
        z-index: 0;
    }

    .opc_navigation {
        li {
        }
    }

    .progressbar {
        margin: 0;
        padding: 0;
        counter-reset: step;
    }

    .progressbar li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 80%;
        position: relative;
        text-align: center;
        text-transform: capitalize;
        color: rgba($offblack, 0.5);
    }

    .progressbar li:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 27px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .progressbar li:after {
        width: 100%;
        height: 2px;
        content: "";
        position: absolute;
        background-color: #7d7d7d;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        color: $green;

        span {
            position: relative;
            top: -15px;
        }
    }

    .progressbar li.active:before {
        border-color: $green;
        width: 50px;
        height: 50px;
        top: -10px;
        position: inherit;
        padding-top: 10px;
    }

    .progressbar li.active + li:after {
        background-color: $green;
    }

    .form-group.radio &:not(#home_business_input) {
        input[type="radio"] {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input[type="radio"] + label:before {
            font-family: "Font Awesome 5 Pro";
            display: inline-block;
            content: "\f00c";
            letter-spacing: 10px;
            font-size: 1.2em;
            color: $cream;
        }

        input[type="radio"]:checked + label:before {
            content: "\f00c";
            font-size: 1.2em;
            color: $orange;
            letter-spacing: 5px;
            width: 5px;
        }

        input[type="radio"]:focus + label:before {
            font-weight: bold;
            color: $orange;
        }
    }

    .op_userfields div.field_wrap.radio {
        input[type="radio"] {
            width: 2em;
            margin: 0;
            padding: 0;
            font-size: 1em;
            opacity: 0;
            float: left;
        }

        _:-ms-fullscreen,
        :root .CLASS_NAME {
            input[type="radio"] {
                opacity: 1;
            }
        }

        .formField label {
            display: inline-block;
            margin-left: -2em;
            line-height: 1.5em;
            padding-left: 0;
            float: left;
        }

        .formField label:not(:first-of-type) {
            margin-left: 3em;
        }

        input[type="radio"] + label > span:before {
            font-family: "Font Awesome 5 Pro";
            display: inline-block;
            content: "\f1db";
            color: #535353;
            font-size: 1.4em;
            width: 1.4em;
            position: relative;
            top: 0em;
        }

        input[type="radio"]:checked + label > span:before {
            font-family: "Font Awesome 5 Pro";
            display: inline-block;
            /*ok sing: content: "\f05d";*/
            content: "\f14a";
            /*letter-spacing: 5px;*/

            color: $orange;
            font-size: 1.4em;
            width: 1.4em;
            position: relative;
            top: 0em;
        }
    }

    #payment_info {
        padding: 20px;
    }

    #payment_info:empty {
        padding: 0px;
    }

    .ajaxstatuswrap {
        background-color: lighten($green, 40%);
        border: none;
        @include radius();
    }

    .vmCartPaymentLogo img {
        height: 40px !important;
    }

    .estimator_wrap {
        font-size: 80% !important;

        .vmshipment {
            display: block !important;
        }

        .vmshipment_name,
        input,
        br {
            display: none;
        }

        .vmshipment_description {
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }

        .opc_ship_wrap {
            label {
                margin-bottom: 0px !important;
            }
        }
    }

    /*shipment and payment radios*/

    // .form-check-input { position: absolute;}
    .cardNumber .__PrivateStripeElement,
    .cardInput div {
        @extend .w-100;
    }
    // .cardNumber .__PrivateStripeElement:after {
    //     font-family: "Font Awesome 5 Pro";
    //     display: inline-block;
    //     position: absolute;
    //     right: 0px;
    //     top: -3px;
    //     /*ok sing: content: "\f05d";*/
    //     content: "\f023";
    //     /*letter-spacing: 5px;*/

    //     color: $green;
    // }

    // #cardCvc_6 .__PrivateStripeElement:after {
    //     font-family: "Font Awesome 5 Pro";
    //     display: inline-block;
    //     position: absolute;
    //     right: 0px;
    //     top: -3px;
    //     /*ok sing: content: "\f05d";*/
    //     content: "\f09d";
    //     /*letter-spacing: 5px;*/

    //     color: $green;
    // }

    #payment_id_0,
    label[for="payment_id_0"] {
        display: none;
    }

    .opc_payment_wrap_4,
    .opc_payment_wrap_1 {
        width: 100%;
    }

    .hr-text {
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: $offblack;
        text-align: center;
        height: 1.5em;
        opacity: 1;

        &:before {
            content: "";
            // use the linear-gradient for the fading effect
            // use a solid background color for a solid bar
            background: linear-gradient(to right, transparent, #818078, transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
        }

        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            color: black;

            padding: 0 0.5em;
            line-height: 1.5em;
            // this is really the only tricky part, you need to specify the background color of the container element...
            color: #818078;
            background-color: #ffffff;
        }
    }

    label {
        margin-bottom: 0px !important;
    }

    /*.opc_payment_wrap { border-bottom: lighten($offblack,65%) 1px solid; }
	.payment_inner_html .opc_payment_wrap:last-of-type {border-bottom: none !important; background-color: rgba(241,0,253,1.00)}
	.payment_inner_html {border: lighten($offblack,65%) 1px solid; @include radius()}*/
    .staddresswrapper {
        /*padding: 10px; border: lighten($offblack,70%) 1px solid; font-size: 90%*/
    }

    // .opc_payment_wrap {
    //     padding-top: 7px !important
    // }

    .staddresswrapper {
        div[id^="opc_st_"] {
            // position: absolute;
            // top: 8px;
            // right: 10px;
            // display: block
        }
    }

    .select_address_wrap {
        [id^="opc_st_"] {
        }
    }

    .select_address_wrap {
        font-size: 90%;

        .form-check {
            padding: 10px !important;

            &.selected {
                @include radius();
                background-color: lighten($green, 45%);
            }
        }
    }

    .opc_payment_wrap,
    #ajaxshipping .opc_ship_wrap {
        // display: flex;
        // padding: 0px 10px !important;
        // width: 100%;

        // &.selected {
        //     background-color: lighten($offblack, 75%);
        //     @include radius();
        // }
    }

    #ajaxshipping .opc_ship_wrap input[type="radio"],
    .opc_payment_wrap input[type="radio"],
    .accordion-item input[type="radio"],
    .select_address_wrap .form-check input[type="radio"] {
        width: 0em;
        margin: 0;
        padding: 0;
        font-size: 1em;
        opacity: 0;
        float: left;
    }

    #ajaxshipping .opc_ship_wrap label {
        /*display      : inline-block;*/
        // margin-left: -2em;
        margin-bottom: 0px;
        line-height: 2.5em;
        padding-left: 0;
        float: left;
    }

    #ajaxshipping .opc_ship_wrap label:not(:first-of-type),
    .opc_payment_wrap label:not(:first-of-type),
    .select_address_wrap .form-check label:not(:first-of-type) {
        margin-left: 3em;
    }

    // #ajaxshipping .opc_ship_wrap input[type=radio]+label>span:before,
    // #ajaxshipping .opc_ship_wrap input[type="radio"] + span.inputfake:before,
    // .opc_payment_wrap span.inputfake:before,
    // .select_address_wrap .form-check input[type=radio]+label>span:before

    .accordion-item input[type="radio"] + span.inputfake:before {
        font-family: "bootstrap-icons" !important;
        display: inline-block;
        content: "\F28A";
        color: $primary;
        font-size: 1.4em;
        width: 1.4em;
        position: relative;
        top: 0em;
        float: left;
    }

    .select_address_wrap .form-check input[type="radio"] + label > span:before {
        top: -0.2em !important;
    }

    // #ajaxshipping .opc_ship_wrap input[type=radio]:checked+label>span:before,
    // .opc_payment_wrap.selected span.inputfake:before,
    // .select_address_wrap .form-check input[type=radio]:checked+label>span:before

    .accordion-item.selected input[type="radio"] + span.inputfake:before,
    .accordion-item .form-check-label.selected span.inputfake:before,
    .accordion-item input[type="radio"]:checked + span.inputfake:before { //selected Styles
        font-family: "bootstrap-icons";
        display: inline-block;
        content: "\F26A";
        color: $secondary;
        font-size: 1.4em;
        width: 1.4em;
        position: relative;
        top: 0em;
    }

    /*shipmgnet and paymetn radios end*/

    /*chckeboxes bottom*/
    input[type="checkbox"].bottom_field {
        width: 2em;
        margin: 0;
        padding: 0;
        font-size: 1em;
        opacity: 0;
        float: left;
    }

    label.bottom_label {
        display: inline-block;
        margin-left: -2em;
        line-height: 1.5em;
        padding-left: 0;
        float: left;
    }

    label.bottom_label:not(:first-of-type) {
        margin-left: 3em;
    }

    input[type="checkbox"].bottom_field + label > span:before {
        font-family: "bootstrap-icons";
        display: inline-block;
        content: "\f0c8";
        color: $secondary;
        font-size: 1.4em;
        width: 1.4em;
        position: relative;
        top: 0.1em;
    }

    input[type="checkbox"].bottom_field:checked + label > span:before {
        font-family: "bootstrap-icons";
        display: inline-block;
        /*ok sing: content: "\f05d";*/
        content: "\f0c8";
        /*letter-spacing: 5px;*/

        color: $orange;
        font-size: 1.4em;
        width: 1.4em;
        position: relative;
        top: 0.1em;
    }
}

#subtotal_box {
    text-align: right;
}

.totalam_wrap {
    font-weight: 500 !important;
}

#tt_total_div {
    font-size: 250%;
    color: $green;
    font-weight: 500;
}

#edit_address_list_st_section label {
}

#ajaxshipping {
    span.vmshipment {
        display: flex;
    }

    span.vmshipment_name {
        display: none;
    }

    br {
        display: none;
    }
}

span.vmshipment {
    .vmshipment_description {
    }
}

#register_container {
    input {
    }
}

.addressfinder {
    display: none;
    background: fadrgbaeout($orange, 0.1);
}

.addressfinder.show {
    display: block;
}

#delivery-output {
    font-weight: 500;
    text-align: center;
}

#delivery-output.col {
    display: none;
}

#delivery-output.col.active {
    display: block;
}

.checkoutfooter {
    .nav-link {
        color: $offblack;

        &.active {
            color: $orange;
        }
    }

    @include text-sm;
    color: lighten($offblack, 20%);

    background-color: lighten($offblack, 80%);
}

#vmMainPageOPC,
#adminForm,
.greywrap {
    i,
    svg {
        color: $offblack;
        fill: $offblack;
    }

    .btn-orange {
        background-color: $orange;
        color: $white;

        i,
        svg {
            color: $white;
            fill: $white;
        }
    }

    .cust_info {
        font-size: 90%;
    }

    .card .card {
        border: lighten($offblack, 50%) 1px solid;
        margin: 0px;
        padding: 0px;
    }

    .car_form .card {
        @include shadow();
    }

    .card {
        border-color: $cream;
        // @include radius(0);
        border-width: 0px;
        margin-bottom: 0px;
        padding: 20px 20px;

        @media (max-width: $screen-xs-max) {
            padding: 10px 10px !important;
        }

        &.condensed {
            font-size: 14px !important;

            .op_col1 {
                height: 30px !important;
            }

            .row {
                margin-bottom: 5px !important;
                padding-bottom: 5px !important;
            }
        }

        &.basketwrap {
            i,
            svg {
                color: $white !important;
                fill: $white !important;
            }
        }

        a.deleteproduct {
            i {
                color: #d5292c !important;
            }
        }

        .shipperwrap {
            // @include text-sm;

            i.band1,
            svg.band1 {
                color: #787776 !important;
            }

            i.band2,
            svg.band2 {
                color: #74a22f !important;
            }
        }
    }

    color: lighten($offblack, 15%);

    .qty.form-control {
        width: auto;
        padding: 0px 2px;
        text-align: center;
    }

    .totalrow {
        font-size: 120% !important;
        color: lighten($offblack, 15%);
    }

    #tt_order_subtotal_basket,
    #tt_shipping_rate_basket,
    #tt_total_basket,
    #tt_tax_total_0_basket {
        font-weight: 600;
    }

    #tt_total_basket {
        color: $green;
    }
}

#opc_ship_wrap_77 .wholesaleshippingwrap {
    display: none;
    width: 100% !important;
}

#estimator_shipping_rates_come_here .wholesaleshippingwrap {
    display: block;
    width: 100% !important;
}

.basket-column {
    @media (max-width: 992px) {
        border-left: none !important; /* Hide border on mobile */
    }
}

.accordion-button {color: $primary;}

#accordionPayment,
#accordionShipment,
#shippingAddressAccordion {
    .accordion-button::after {
        display: none;
    }
    .accordion-item:last-of-type {
        border-bottom-left-radius: var(--bs-accordion-border-radius) !important;
        border-bottom-right-radius: var(--bs-accordion-border-radius) !important;
    }
    --#{$prefix}accordion-color: #{$accordion-color};
    --#{$prefix}accordion-bg: #{$accordion-bg};
    --#{$prefix}accordion-transition: #{$accordion-transition};
    // --#{$prefix}accordion-border-color: #3571ab;
    --#{$prefix}accordion-border-width: #{$accordion-border-width};
    --#{$prefix}accordion-border-radius: #{$accordion-border-radius};
    --#{$prefix}accordion-inner-border-radius: #{$accordion-inner-border-radius};
    --#{$prefix}accordion-btn-padding-x: #{$accordion-button-padding-x};
    --#{$prefix}accordion-btn-padding-y: #{$accordion-button-padding-y};
    --#{$prefix}accordion-btn-color: #{$accordion-button-color};
    --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
    --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon)};
    --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
    --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
    --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon)};
    --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
    --#{$prefix}accordion-body-padding-x: #{$accordion-body-padding-x};
    --#{$prefix}accordion-body-padding-y: #{$accordion-body-padding-y};
    --#{$prefix}accordion-active-color: #3571ab;
    --#{$prefix}accordion-active-bg: #f1f5fe;
}
#accordionPayment {
    .accordion-item:nth-last-of-type(2) {
        .accordion-button {
            border-bottom-left-radius: var(--bs-accordion-border-radius) !important;
            border-bottom-right-radius: var(--bs-accordion-border-radius) !important;
        }
        border-bottom-left-radius: var(--bs-accordion-border-radius) !important;
        border-bottom-right-radius: var(--bs-accordion-border-radius) !important;
    }
}
