footer.footer {
color: $white;
a,h2,h3{color: $highlight;}
ul{ padding-left: 15px;
}
h4{font-size: 1.0rem;}
.small { color: rgba($white,0.7); font-size: 0.8rem;}
i { font-size: 1.0rem;}

.footercats{.level2 a{ }}
.disclaimer {
    background-color: darken($compliment,10%);
    color: #5d656a;
    font-size: 11px;
    padding-top: 15px;
    padding-bottom: 15px;

    a {
      color: #8b969c;
    }
  }
}


.disclaimer {
  background-color: lighten($compliment,10%);
  color: darken($compliment,10%);;
  font-size: 11px;
  padding-top: 15px;
  padding-bottom: 15px;

  a {
    color: #8b969c;
  }
}
