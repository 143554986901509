//Homepage Stuff //

.hpctadarkvan {
    h1{font-size: 2.5rem;}
    h1,h3{ color: $secondary; text-transform: uppercase; font-weight: 100;}
    h3{ font-size: 1.5rem;}
    font-weight: 100;
    font-size: 0.9rem;
    }
    .imgwrap { width: 100%; height: 200px;}
    .specofferswrap { z-index: 1015; top:-270px}
    .spacedinfobar {
        color: $darkgrey;
        font-size: 0.8rem;
        .bi::before {
            color: $secondary;
          }
          p{ color: rgba($darkgrey,0.5);}

    }

    .hpcatswrapper {
        .itemwrapper {
            .text-wrap {
                p {
                    display: none;
                    &:first-of-type {
                        font-size: 0.8rem;
                        display: block;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            &:hover{ background-color: rgba($darkgrey,0.1);}
        }
    }

    .gethelpwrap {
        ul{ list-style: none;

    }
    a,h3{ color: $white !important}
    }

    .blogitem{ h3 a, a{ color: $compliment; &:hover{ color: $secondary; text-decoration: underline;}} p{font-size: 0.9rem;}}
    .fullwidthbg{
        @include fullwidthbgimg;
    }
    .fwcta {

        overflow-x: hidden;
        @include fullwidthbgimg;

        img.background {
          object-fit: cover;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }

        clip-path: inset(0);
        min-height: 55vh !important;
        position: relative;
        color: #fff;

        h2 {
          color: #fff;
          font-size: 2rem;
        //   @include seriffonts;
        }
        p.lead {
          font-size: 1rem;
        }
        &::after {
          content: "";
          display: block;
          background-color: #091a349f;
          z-index: 1;
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
        }
        .container {
          z-index: 3;
          position: relative;
          max-width: 1100px;
        }
      }

      .imagebgwrap.fwcta {
        color: $cream;
        font-size: 90%;

        h1 {
          font-size: 200%;
          @include seriffonts;
        }

        h2 {
          font-size: 180%;
          font-weight: 100;
        }

        h1,
        h2,
        a {
          color: $primary;
        }
      }
    .hpbrands {
        a img {
            transform: scale(0.8);
            -webkit-filter: grayscale(100%);
            transition: transform 350ms cubic-bezier(0.38, 1.25, 0.79, 0.99);
            // transition: 150ms cubic-bezier(0, 0, 0.2, 1);

            &:hover {
                transform: scale(1);
                -webkit-filter: grayscale(0);
            }
        }
        h2 {
            //   color: lighten($offblack,30%);
            color: $primary;
            font-size: 2.2rem;
            @include seriffonts;
        }
    }
    .introcta {
        @include fullwidthbgimg;

        position: relative;
        color: $offblack;
        h1 {
            //   color: lighten($offblack,30%);
            color: $primary;
            font-size: 2.2rem;
            @include seriffonts;
        }

        p.lead {
            font-size: 1.1rem;
            line-height: 1.9em;
        }
        .container {
            z-index: 3;
            position: relative;
            padding: 100px 0px;
            max-width: 1100px;
        }
    }


    section.brandbar {
        background-color: rgba(darken($secondary, 10%), 0.93);
    }

    section.trustpilot {
        background-color: rgba(darken($secondary, 20%), 0.95);
        padding: 40px 40px 80px;

        h2 {
            font-weight: 100;
            margin-bottom: 20px;
            color: $primary;
        }

        .orange {
            color: $primary;
        }

        .tpheader {
            margin-top: 35px;
        }

        h3 {
            color: $primary;
            margin: 0px;
        }

        p {
            font-size: 14px;
        }

        .reviewwrap {
            margin-bottom: 15px;
        }
    }

    section.blog {
        /*background-image: url(../images/nov-2016/blog-bg.jpg); background-size: cover; background-repeat: no-repeat;*/
        padding: 40px 0px;

        h2 {
            font-size: 32px;
            font-weight: 100;
            color: $primary;
            margin-bottom: 30px;
        }

        .blogpostwrap {
            background-color: #ffffff;
            @include shadow(5px);
            margin-bottom: 15px;
        }
    }

    //Homepage Stuff //
