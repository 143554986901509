.btn,
.inputgroup,
.form-control {
    //   @include radius(15px);
}

.btn-success {
    color: $white !important;

    i,
    svg {
        color: $white !important;
    }
}

.btn-xs {
    font-size: 90%;
    padding: 2px 5px;
}

.btn-xl {
    font-size: 32px;
}

.btn-xxl {
    font-size: 52px;
}
.btn-outline-orange {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
}

.btn-orange {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
}
.btn-outline-secondary {
    color: $white !important;
    background-color: rgba($secondary, 0.2);
}

.btn {
    .subtext {
        @include text-sm;
    }
}
.btn-stripe {
    color: $white;
    border: 2px $primary solid;
    &:hover {
        background: transparent;
        color: $primary;
    }
}

.btn-outline-light {
    color: lighten($primary, 5%);
    background-color: #2d2d2d22;
    border-color: #f8f9fa;
}
.btn-outline-dark {
    color: lighten($primary, 5%);
    border-color: $offblack;
}

.checkoutbutton,
.vmCartModule .btn-success,
.checkout_step_1 .btn-success {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
#payment_top_wrapper {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
