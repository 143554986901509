nav.navbar {
    z-index: 1000  !important;

    // background-color: darken($compliment,20%) !important;
    @media screen and (max-width: 830px) {
         background-color: $secondary !important;
      }


  .mobilenav {
    i {
      font-size: 1rem;
    }

    a {
      padding: 0px !important;
    }
  }

  button.navbar-toggler {
    padding: 0px;
    border: none;

    &:focus {
      background-color: darken($secondary,20%);
    }
  }

  .navbar-toggler-icon {
    color: #ffffff;
  }

  .toplevel > a:hover { position: relative; }
  a {
    font-size: 1.2rem;
    color: darken($white,5%);
    padding: 11px 20px;
    font-weight: 500;



    &:hover {
      color: lighten($orange, 10%);
      text-decoration: none;


    }

    &.active {
      color: lighten($orange, 10%);

    }
  }


}

.subnav {

    font-size: 0.8rem;
    i{font-size: 1.5rem; }

}
