
nav.breadcrumb {
    padding: 5px 0px 3px;

    ol.breadcrumb {
        font-size: 0.8rem;
        background-color: transparent !important;
        padding: 0px;
        margin: 0px;
        color: lighten($darkgrey,30%);
        a{ color: lighten($darkgrey,30%);}
    }

}

.breadcrumb {

    background-color: transparent !important; //fadeout($cream,10%);
    border-radius: 1rem;

    svg.separator {

        fill: darken($cream, 20%) !important;
    }
}

.breadcrumb-item+.breadcrumb-item::before {
    @include fa;

    //content: "\f054";

}


.breadcrumb-item.active {
    color: $secondary;
    font-weight: 600;
}