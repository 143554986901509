$primary: #1D1D1B;
$secondary: #009844;
// $secondary: #8a8a8a;
// $secondarymuted: lighten(desaturate($secondary,20%),20%);
$compliment: #2d363d;
$blue: rgb(5, 70, 98);
$grey: #e7e4e4;

$darkgrey: #404040;
$lightorange: lighten($primary, 20%);
$highlight: #51b37f;
$success: #65ba10;
// $success: $secondary;
$white: #fff;
$cream: #ece8e4;
$gold: #dfcfa3;
$offblack: #222222;
$green: $primary;
$card-border-radius: 0px;
$accordion-border-radius: 10px;
$accordion-button-active-bg: rgba($secondary, 0.8);
$accordion-button-active-color: $white;
$accordion-button-color: rgba($secondary, 0.8);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "secondarydark": darken($secondary,20%),
    "secondarymuted": lighten(desaturate($secondary,90%),60%),
    "compliment": $compliment,
    "highlight": $highlight,
    "white":$white,
    "cream":$cream,
    "green":$secondary,
    "grey": $grey,
    "darkgrey": $darkgrey,
    "stripe": $primary,
    "danger": rgb(187, 2, 2),
    "charcoal": rgb(54, 54, 58),
    "muted": rgb(154, 154, 161),
    "blue": $blue,
    "success": $success,
    "checkout": $success,
    "light": rgb(231, 235, 240),
    "dark": $offblack
);
$border-radius:               .375rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            1rem;
$border-radius-2xl:           2rem;
$border-radius-pill:          50rem;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
