html {
    font-size: 0.8rem;
}

body {
    //font-family: avenir,"Lucida Grande","Lucida Sans Unicode","Lucida Sans","DejaVu Sans",Verdana,sans-serif;
    font-family: 'Montserrat', sans-serif;
    //   font-family: "Poppins", sans-serif;
    // font-family: "Quicksand", sans-serif;
    //   overflow-x: hidden;
}

.break-out {
    width: 100vw;
    position: relative;

    left: calc(-1 * (100vw - 100%) / 2);
}

.bgcover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.affix {
    top: 0;
}

.hoverscale {
    transform: scale(1);
    transition: transform 350ms cubic-bezier(0.38, 1.25, 0.79, 0.99);
    // transition: 150ms cubic-bezier(0, 0, 0.2, 1);

    &:hover {
        transform: scale(1.05);
    }
}

a {
    color: $offblack;
    text-decoration: none;

    &:hover {
        color: $primary;
    }
}
a.nav-link {
    display: block !important;
}

#modaladdJS {
    .btn-close {
        padding: 0px;
    }
}

.modal-header {
    background-color: $primary;

    h3 {
        color: $white;
    }

    .close {
        text-shadow: none;
    }
}

.vm-wrap {
    fieldset {
        border: $primary 2px solid;
        @include radius();

        legend {
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 20px;
        }
    }
}

.parallax-background {
}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.bigcard {
    h2 {
        font-size: 2rem;
        @include seriffonts;
        color: #d39048;
    }
    p {
        font-size: 1rem;
        @include seriffonts;
    }
    .btn-outline-dark {
        background-color: transparent;
        border: $offblack 2px solid;
        color: $offblack;
    }
}

.iframe-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}

/* Make the iframe keep an aspect ratio, and
  position it in the middle of its parent wrapper*/

.iframe-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fwctavideo {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;

    // &::after{ content: '';  display: block; background-color: #091a3473; z-index: 1; position: absolute;  top: 0px; width: 100%; height: 100%;}
    .container {
        z-index: 3;
        position: relative;
        max-width: 1100px;
    }

    iframe {
        width: 100vw;
        height: 26.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.popover.login {
    max-width: 600px !important;
    @media screen and (max-width: 830px) {
        left: -160px !important;
    }

    h3 {
        font-size: 170%;
    }

    h3,
    a {
        color: $primary;
    }

    a.btn {
        color: $white;
    }
}

.popover-body {
    width: 300px;
}

.popover-header {
    background-color: $white;
    border: none !important;
}

.popover-body {
    font-size: 90%;
    @include gradient(lighten($cream, 5%));
    @include radius();
    @include shadow();

    li {
        list-style: none;
        padding: 0px;

        &:before {
            @include fa;
            content: "\f054";
            color: $primary;
            margin-right: 10px;
        }

        a {
            color: $offblack !important;
        }
    }

    ul {
        list-style: none;
        padding: 0px;
    }
}

.trending {
    li {
        list-style: none;
    }

    .vm-product-descr-container-1 {
        a {
            font-size: 90%;
        }
    }
}

/*Generic Price styling*/
.PricepriceWithoutTaxTt,
.PricesalesPriceTt.NoVAT {
    color: $blue;
}

.pricesmall,
.PricesalesPriceTt.pricesmall {
    color: lighten($offblack, 30%) !important;
}

/*Generic Price styling*/

.backgroundimgwrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

div.main.container {
    background-color: $white;
    padding-top: 15px;
}

div.main.container {
    &.cart,
    &.checkout {
        background-color: transparent !important;
    }
}

.searchouterwrap {
    @include shadow();
}

.searchinnerwrap {
    background-color: #fff;
}

#catsearchresults {
    a.category {
        color: $primary !important;
    }
}

#searchresults {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 600px;

    #vm2-search {
        ul {
            list-style: none;
            padding: 0px;
        }
    }

    .vm2_search_add_image {
        img {
            width: 75px !important;
            height: 75px !important;

            @media screen and (max-width: 830px) {
                width: 100px !important;
                height: 100px !important;
            }
        }
    }

    h2 {
        a {
            font-size: 80%;

            @media screen and (max-width: 830px) {
                font-size: 100% !important;
            }
        }
    }
}

.fastbuy {
    .PricepriceWithoutTaxTt {
        color: $primary;
        font-size: 150%;
        font-weight: 500;

        @media screen and (max-width: 830px) {
            font-size: 100% !important;
        }
    }

    .pricesmall {
        @media screen and (max-width: 830px) {
            display: none;
        }
    }
}

.input-group-append .btn,
.input-group-prepend .btn {
    z-index: 0 !important;
}

.mainprodrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.mainprodrow > [class*="col-"] {
    display: flex;
    flex-direction: column;
}

ul.myaccountwrap {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
        display: inline-table;

        a {
            font-size: 80%;
        }
    }
}

.slick-prev,
.slick-next {
    font-size: 100%;
    top: 44%;
}

.slick-prev:before,
.slick-next:before {
    font-size: 200%;
    color: $offblack;
}

.slick-prev:before {
    @include fa;
    content: "\F284";
}

.slick-next:before {
    @include fa;
    content: "\F285";
}

.prod-mod-wrap {
    .addtocartbar {
        display: none !important;
    }

    h3 {
        font-size: 90%;
    }

    font-size: 90%;

    input[type="checkbox"]:checked + label {
    }

    label:before {
        font-size: 110%;
    }

    .PricepriceWithoutTaxTt {
        font-size: 100% !important;
    }
}

/*Prices Styles*/

.product-related-products,
.youmayalsoneed,
.childprodwrap {
    text-align: center !important;

    h5 {
        @include text-sm;
        text-align: center;
    }

    .vm-product-media-container a {
        float: left;
        text-align: center !important;
    }

    small {
        font-size: 80% !important;
        line-height: 100% !important;
    }

    .qtywrap {
        display: none !important;
    }

    .flex-grow-1 {
        flex-grow: 0 !important;
    }

    button.btn-addtocart,
    .btn-danger {
        font-size: 70%;
        padding: 4px 6px;
        text-align: center;
    }

    .pricewrap {
        display: inline;
    }

    .PricepriceWithoutTaxTt,
    .PricesalesPriceTt.NoVAT {
        text-align: center;
        font-size: 120% !important;
        line-height: 100% !important;
    }

    .pricesmall {
        display: none;
        font-size: 70% !important;
        line-height: 100% !important;
        display: inline-block;

        &.zerovat,
        &.incvat {
            display: none;
        }
    }

    .PricevariantModification {
        display: none !important;
    }
}

.PricevariantModification {
    display: none !important;
}

/*Prices Styles*/

.countdownwrap {
    flex-wrap: nowrap;
    min-width: 260px;

    #countdown {
        font-size: 80%;
    }

    .time {
        flex-wrap: nowrap !important;
    }

    .smalltext {
        font-size: 70%;
    }

    .icon i {
        font-size: 120%;
    }

    //   #gmctime-countdown1 {
    //     color: #fff;
    //   }
    //   #countdown-mobile {
    //     font-size: 80%;
    //     strong {
    //       color: $primary !important;
    //     }
    //   }

    @media screen and (max-width: 830px) {
        a i {
            color: $primary !important;
            font-size: 120% !important;
        }
    }
}

.mobile-countdown {
    z-index: 1019;
    background-color: $offblack;
    color: $white;
}

.cf_wrapp_all {
    ul {
        padding: 0px;

        li {
            padding: 0px;
        }

        li.cfLiLevel0 {
            font-size: 18px;
        }

        li.cfLiLevel1 {
            font-size: 18px;
            padding: 0px;
            margin-left: 5px !important;

            a {
                color: lighten($offblack, 30%);
            }
        }

        li.cfLiLevel2 {
            font-size: 14px;
            padding: 0px;
            margin-left: 5px !important;
        }
    }

    a {
        color: $offblack;
    }
}

.minicarthdr {
    p {
        padding: 0px;
        margin: 0px;
    }
}

.mini-cart {
    text-align: left !important;

    .description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .vm_cart_products {
        @include text-sm;
    }

    .shopping-cart-header {
        .row {
            background-color: lighten($offblack, 75%);
            margin-bottom: 5px;
        }
    }

    .shopping-cart-total {
        @include text-md;
        color: $primary;
        font-weight: 500;
    }

    #tt_total_basket_div_basket {
        font-size: 150%;
        font-weight: 500;
    }
}

.stickybasketwrap {
    @media screen and (max-width: 500px) {
        position: relative;
        margin-top: 0px !important;
    }
}

.stickybasket {
    @media screen and (max-width: 500px) {
        position: relative;
        padding-top: 0px !important;
    }
}

.card {
}

.smalldesc,
.product-description-wrap,
ul.ticked {
    ul {
    }

    li {
        padding: 0px;
        padding-left: 5px;
        &::marker {
            @include fa;
            content: "\F270";
            color: $highlight;
            margin-right: 5px;
        }

        // &:before {
        //     @include fa;
        //     content: "\f00c";
        //     color: $green;
        //     margin-right: 10px;
        // }
    }
}

ul.ticked {
    padding-left: 15px;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

#fancybox-wrap {
    #fancybox-title {
        width: 100%;
        margin: 0px !important;
        text-align: center;
        width: 100% !important;
    }

    @media screen and (max-width: 830px) {
        width: 100% !important;
        left: 0px !important;
    }

    #fancybox-content {
        @media screen and (max-width: 830px) {
            width: 100% !important;
            height: auto !important;
            padding: 5px !important;
        }
    }

    .popupheader {
        h4 {
            color: $offblack;

            i,
            svg {
                color: $primary;
            }
        }

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;

            i,
            svg {
                color: $green;
            }
        }
    }
}

#fancybox-outer,
#fancybox-wrap {
    @include radius();

    .product-related-products {
        background-color: $cream;
    }
}

#fancybox-content {
    border-width: 0px !important;

    h3 {
        color: darken($cream, 50%);
    }

    .product-field-display {
        h5 {
            @include text-sm;
        }

        img {
            max-width: 100px;
            height: auto;
        }
    }
}

.popupwrapper {
    width: 500px;
    @media screen and (max-width: 830px) {
        width: 100% !important;
    }
}

.popupheader {
    background-color: $white;
    color: $offblack;
    @include text-md;
}

/*Basket styles*/

/*Form Wizard$green*/
.bs-wizard {
    border-bottom: solid 1px #e0e0e0;
    padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: lighten($green, 30%);
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: " ";
    width: 14px;
    height: 14px;
    background: $green;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: lighten($green, 30%);
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}

/*END Form Wizard*/

/*Basket Nav*/

#first_name_input,
#shipto_first_name_input {
    .form-control {
        width: 49% !important;
        display: inline !important;
    }
}

.greywrap {
    background-color: lighten($cream, 5%);
    /*background-color: #e5d9cb;*/
}

/* Move reCAPTCHA v3 badge to the left */

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
    @media (max-width: $screen-xs-max) {
        left: -9999rem !important;
    }
}

.grecaptcha-badge:hover {
    width: 256px !important;
}

.container.checkout {
    width: 1200px;

    @media (max-width: $screen-xs-max) {
        width: 100%;
    }

    .countdownwrap {
        margin-top: 0px !important;
    }

    .countdownwrap #countdown {
        font-size: 14px;
    }

    .countdownwrap .icon i {
        font-size: 24px;
    }
}

.container.orders,
.container.user,
.container.article {
    width: 1200px;

    @media (max-width: $screen-xs-max) {
        width: 100%;
    }
}

/*Forms*/

.form-control {
    background-color: lighten($cream, 7%);
    border: darken($cream, 5%) 1px solid;
    // @include radius(0px);
}

.col-form-label {
    &.required {
        label:after {
            //font-family: FontAwesome;
            display: inline-block;
            content: "\*";
            color: #d3494c;

            font-variant-position: super !important;
            margin-left: 5px;
        }
    }

    font-size: 0.875rem;
}

.form-group.bank_account_nr,
.form-group.po_compulsary,
.form-group.admin_cust_note,
#admin_cust_note_div,
#admin_cust_note_input,
.form-group.marked_for_social,
#marked_for_social_div,
#marked_for_social_input {
    display: none !important;
}

/*Forms*/

input.formcontrol[type="checkbox"] {
    width: auto !important;
}

.freeshipping {
    i,
    svg {
        color: $green !important;
    }
}

/*Radios*/

.text-center {
    text-align: center;
}

.thick-end {
    border-bottom: 10px solid black;
}

.thin-end {
    border-bottom: 1px solid black;
}

.formContainer {
    .formRequired {
        color: red;
    }

    fieldset:nth-child(n + 2) {
        border: $primary 2px solid;
        @include radius();
        padding: 20px;

        .form-control,
        .control-label {
            font-size: 80%;
        }

        label {
            vertical-align: middle !important;
        }

        legend {
            font-size: 120%;
            color: $secondary;
            display: inherit;
            width: auto;
            padding: 0px 10px;
        }
    }
}

.g-recaptcha {
    margin: 15px auto !important;
    width: auto !important;
    height: auto !important;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center;
    text-align: -ms-center;
}

div.icons {
    display: none;
}

div.blog {
}

.item-page,
div.blog {
    h1 {
    }

    p {
    }
}
.slug_blog {
    .articletext {
        column-count: 2;
        @media (max-width: $screen-xs-max) {
            column-count: 1;
        }
    }
    .introtext {
        color: $secondary;
        font-size: 1.5rem;
    }
}

.jobapp {
    fieldset {
        padding: 10px !important;

        .form-group {
            margin-bottom: 2px;

            label {
                margin-bottom: 0px;
            }

            .form-control {
                padding: 5px;
                height: auto;
            }
        }

        button {
            width: 100%;
        }
    }
}

.form-control-file {
    font-size: 80%;
}

.zendesk-button:focus {
    outline: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.zendesk-button {
    outline: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.myaccount-wrap {
    .card {
        p {
            height: 70px;
            @media screen and (max-width: 830px) {
                height: auto !important;
            }
        }
        a {
            color: $primary !important;
            font-weight: 600;
        }
    }
}

.login-myaccount-wrap {
    a {
        border: none !important;
        &:hover {
            background-color: lighten($offblack, 80%);
        }
    }
    h3 {
        @media screen and (max-width: 830px) {
            font-size: 1.2rem;
            color: $offblack;
        }
    }
    p {
        font-size: 0.8rem;
        @media screen and (max-width: 830px) {
            font-size: 1rem;
            color: lighten($offblack, 20%);
        }
        color: lighten($offblack, 50%);
        margin-bottom: 0px;
    }
}
