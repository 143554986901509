#payment_response {
	color: white;
}
#payment_response {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #333333d1;
   z-index: 99999;
}



#payment_response { display: flex; justify-content: center; align-items: center; }



#payment_response.modal--show,
#payment_response.modal--hide { display: flex; } /* classes fired by js for animation control */

/* This is on the wrapper for the whole modal */
#payment_response div.payment_wrap_after_checkout > .StripeElement {
    max-width: 400px;
	width: 400px;
    @media (min-width: 100px) and (max-width: 879px) {

   max-width: 360px;
	width: 360px;

}
}

.payment_wrap_after_checkout { display: flex; justify-content: center; align-items: center; flex-wrap: wrap; flex-flow: column wrap;}
.payment_wrap_after_checkout > * {

}
#payment_response.modal--align {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

#payment_response .payment_wrap_after_checkout {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 800px;
  padding: 20px;
  padding-left: 20%;
  padding-right: 20%;
  margin: 0;
  color: white;
}

/* The .modal__close class is used in js but is modified '--x' here */
#payment_response .modal__close--x {
  font-size: 30px; /* this is only because we use unicode for the X in this case */
  position: absolute;
  top: 3px;
  right: 10px;
}

/* As there is no href to avoid the hash being added to the URL when clicked we add a pointer */
/* This 'x' is hidden from screen readers as there is an accessible close button in the modal */
#payment_response .modal__close--x:hover {
  cursor: pointer;
}

/* Animations */
/* Open */
#payment_response .modal.modal--show {
  animation: modal-open 0.3s;
}

@keyframes modal-open {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

/* Close */
#payment_response.modal--hide {
  animation: modal-close 0.3s;
}

@keyframes modal-close {
  0%    { opacity: 1; }
  100%  { opacity: 0; }
}

#payment_response button {
	min-width: 100px;
	min-height: 30px;
}
@media (min-width: 100px) and (max-width: 879px) {

#payment_response .payment_wrap_after_checkout {
  padding-left: 20px;
  padding-right: 20px;
}

}

#payment_response a.cancell {
 line-height: 2em;
}

#payment_response .wait_on_ready {
	display: none;
}
// #payment_response .wait_on_ready > button {
// 	line-height: 2em;
// 	margin-top: 5px;
// 	margin-bottom: 5px;
// 	padding: 5px;
// 	background-color: #5acc31;
// 	outline: none;
// 	border: 0px solid transparent;
// 	border-radius: 3px;
// }
#payment_response a.cancell,#payment_response a.cancell:visited,#payment_response a.cancell:hover {
	color: white;
}
#payment_response a.cancell:hover {
	color: black;
}

#payment_response .spinner.hidden {
display: none;
}
#payment_response .error {
	font-weight: bold;
	color: red;
}