.childcatswrapper {
    background-color: #f2f0f1 !important;
    $cattext: #41476f;
    color: $cattext;
    a {
        color: $cattext;
    }
    h1,
    h3 {
        color: darken($cattext, 0%);
    }
    .h1wrap {
    }
    .feature {
        ul {
            list-style: none;
            li {
                font-size: 0.8rem;
            }
        }
    }
}

.childcats_iconview {

    h1{
        @media screen and (max-width: 830px) {
          font-size: 1.5rem ;
        }

    }

    .stretched-link {@media screen and (max-width: 830px) {
        font-size: 0.8rem ;
        line-height: 1rem;
      }}

    .description { columns: 3;
        @media screen and (max-width: 830px) {
            columns: 1;
          }
        p{ margin-bottom: 5px;}font-size: 0.8rem; color: $compliment; a{ color: $secondary; font-weight: bold;}}


        /* Add custom CSS for the hover effect */
  .hover-div {
    position: relative;
    overflow: hidden;
  }

  .hover-div::before, .hover-div::after, .inner::before, .inner::after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .hover-div::before {
    border-top: 1px solid $highlight; /* Adjust color and size as needed */
    border-left: 1px solid $highlight;
    top: 0;
    left: 0;
    width: 20px; /* Adjust the size as needed */
    height: 20px;
  }

  .hover-div::after {
    border-bottom: 1px solid $highlight; /* Adjust color and size as needed */
    border-right: 1px solid $highlight;
    bottom: 0;
    right: 0;
    width: 20px; /* Adjust the size as needed */
    height: 20px;
  }
  .inner::before {
    border-top: 1px solid $highlight; /* Adjust color and size as needed */
    border-right: 1px solid $highlight;
    top: 0;
    right: 0;
    width: 20px; /* Adjust the size as needed */
    height: 20px;
  }
  .inner::after {
    border-bottom: 1px solid $highlight; /* Adjust color and size as needed */
    border-left: 1px solid $highlight;
    bottom: 0;
    left: 0;
    width: 20px; /* Adjust the size as needed */
    height: 20px;
  }

  .hover-div:hover::before, .hover-div:hover::after, .hover-div:hover .inner::before, .hover-div:hover .inner::after {
    opacity: 1;
  }

  .hover-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;


    transition: opacity 0.3s ease;
  }

  .hover-div:hover .hover-content {
    opacity: 1;
  }





}
.category-view,
#searchresults {
    .childcatswrapper {
        .item {
            /*border: 1px $cream solid; .radius; background-color: fadeout($cream,30%);*/
            a {
                font-size: 80%;
                font-weight: 500;
                color: $secondary;
                line-height: 20px;
                display: block;
            }
        }
    }

    .card {
        background-color: $cream;
    }

    h1 {
        text-align: center;
        color: $primary;
        font-size: 2rem;

        font-weight: 400;

        @media screen and (max-width: 830px) {
            font-weight: 500;
            font-size: 130%;
            text-align: center !important;
        }
    }

    h3 {
        font-weight: 100;
        color: $secondary;
    }

    .category_description {
        font-size: 100%;
        font-weight: 400;
        line-height: normal;
    }

    // .readMoreText {color: lighten($offblack,15%); p{ margin: 0px;}}
    .filterbar {
        background-color: $cream;
        @include radius();
    }

    .metadesc,
    .readMoreText,
    .category_description {
        h2 {
            color: $primary;
            font-size: 2.5rem;

            font-weight: 400;
            text-align: center;
        }

        p {
        }

        @media screen and (max-width: 830px) {
            font-size: 80%;
            line-height: normal;
        }
    }

    .browse-view,
    #vm2-search {
        .products-row:last-child {
            .border-bottom {
                border: none !important;
            }
        }

        .product {
            img.browseProductImage {
                max-height: 250px !important;
            }
            .product-container a {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center !important;
                @media screen and (min-width: 830px) {
                    &::after {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgb(143, 143, 143);
                        opacity: 0.03;
                        pointer-events: none;
                        border: #131313 solid 1px !important ;
                    }
                    &:hover::after {
                        background: rgb(0, 0, 0);
                    }
                }
            }

            .highlight {
                background-color: lighten($highlight, 45%);
                border: lighten($highlight, 0%) 1px solid;
                @include radius();
                position: relative;

                img.browseProductImage {
                    @include shadow();
                    @include radius();
                }
            }

            .ribbon {
                position: absolute;
                left: -5px;
                top: -5px;
                z-index: 1;
                overflow: hidden;
                width: 120px;
                height: 120px;
                text-align: right;
            }

            .ribbon span {
                font-size: 12px;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                line-height: 30px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                width: 150px;
                display: block;
                background: lighten($highlight, 0%);
                // background: linear-gradient(lighten($highlight, 40%) 0%, lighten($highlight, 10%) 100%);
                // box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
                position: absolute;
                top: 28px;
                left: -32px;
            }

            .ribbon span::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid #555555;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #555555;
            }

            .ribbon span::after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid #555555;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #555555;
            }

            .product-container {
                position: relative;
            }

            .bottomrow {
            }

            .btn-success {
            }

            h2,
            h3 {
                font-size: 90%;
                text-align: left;
                color: $offblack;
                font-weight: 500;

                a {
                    color: $secondary;

                    @media screen and (max-width: 830px) {
                        font-size: 80%;
                    }
                }
            }

            .PricepriceWithoutTaxTt {
                color: $primary;
                font-size: 1.2rem;
                font-weight: 500;
            }

            .PricesalesPriceTt {
            }

            .pricesmall {
                font-size: 80%;
                color: lighten($offblack, 30%) !important;
                margin-right: 3px !important;
            }
        }

        .product-alt1 {

            ul{
                li{ font-size: 0.8rem !important;}
            }




        }
    }
}
.catheaderbox { a{ color: lighten($highlight,20%); font-weight: 600; &:hover{ text-decoration: underline;}}}

.VMmenuCatmenu {
    padding: 0;
    li {
        a {
            font-size: 0.9rem;
            color: rgba($primary, 0.7);
        }
        &::marker {
            content: "\F285";
            position: relative !important;
            top: 2px;
            color: $grey;
            font-family: bootstrap-icons;
        }
        &.VmOpen {
            a {
                font-weight: 600;
                color: $primary;
            }
            &::marker {
                content: "\F285";
                color: $secondary;
            }
            border-bottom: 1px solid $grey;
            margin-bottom: 10px;
            margin-top: 10px;
            padding-bottom: 3px;
        }
    }
    ul {
        padding-left: 0px;
        li {
            &::marker {
                content: "";
                color: $secondary;
                font-family: bootstrap-icons;
            }
        }
        a {
            font-size: 0.75rem;
            font-weight: 300;
            color: $secondary !important;
        }
    }
}

.catbottom-stripe,
#productstripe.productstripe {
    background-color: $cream !important;

    .grid {
        .imgwrap {
            background-position: center center;
            background-size: cover;
            position: relative;

            .imglink {
                color: #fff;
                background-color: #35353597;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;

                -webkit-transition: background-color 200ms ease-in;
                -moz-transition: background-color 200ms ease-in;
                -o-transition: background-color 200ms ease-in;
                -ms-transition: background-color 200ms ease-in;
                transition: background-color 200ms ease-in;

                p.small {
                    color: #fff !important;
                    opacity: 0.6;
                    -webkit-transition: opacity 400ms ease-in;
                    -moz-transition: opacity 400ms ease-in;
                    -o-transition: opacity 400ms ease-in;
                    -ms-transition: opacity 400ms ease-in;
                    transition: opacity 400ms ease-in;
                }
                &:hover p.small {
                    opacity: 1;
                }

                &:hover {
                    background-color: #39200656;
                }

                h2 {
                    color: $primary;
                    font-size: 250%;
                    margin-bottom: 10px;

                    -webkit-transition: color 200ms ease-in;
                    -moz-transition: color 200ms ease-in;
                    -o-transition: color 200ms ease-in;
                    -ms-transition: color 200ms ease-in;
                    transition: color 200ms ease-in;
                }
                &:hover h2 {
                    color: $white;
                }
            }
        }
    }

    h2 {
        color: $primary;
        font-size: 2.5rem;

        font-weight: 400;
        text-align: center;
        margin-bottom: 40px;
        @media screen and (max-width: 830px) {
            font-size: 9vw;
        }
    }

    p {
        text-align: center;
        color: $offblack;
        margin-bottom: 10px;
        font-size: 90%;
        padding: 0px 100px;
        @media screen and (max-width: 830px) {
            padding: 0px 0px;
        }
    }

    @media screen and (max-width: 830px) {
        line-height: normal;
        padding: 0px 0px;
    }

    .stripes {
        h3 {
            color: $primary;
            font-size: 3.5rem;

            font-weight: 400;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

.catlandingwrapper {
    section.jumbotron {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.filters {
    h3 {
        margin-bottom: 0px;
    }

    .spec {
        margin-bottom: 10px;
    }

    button.w-100 {
        padding: 0px;
        margin: 0px;
        text-align: left;
        font-size: 0.8em;
        color: lighten($offblack, 20%);

        b {
            display: none !important;
        }

        &:before {
            font-family: "Font Awesome 5 Pro";
            display: inline-block;
            content: "\f0c8";
            color: $offblack;
            margin-right: 5px;
        }

        &.btn-default {
            &:before {
                font-family: "Font Awesome 5 Pro";
                display: inline-block;
                content: "\f14a";
                color: $primary;
                margin-right: 5px;
            }
        }
    }
}

.container.browseheading {
    padding-left: 0px;
    padding-top: 25px;
    padding-bottom: 25px;

    .smalldesc {
        color: #fff !important;
        @include textshadow();
        font-size: 100%;
        font-weight: 300 !important;

        a {
            color: #fff;
        }
    }

    h1 {
        color: $gold;
        font-weight: 100 !important;
        font-size: 54px;
        @include textshadow();
    }
}

.catlanding {
    h1 {
        color: #ffffff !important;
        font-weight: 100 !important;
        text-transform: uppercase !important;
    }
}
