.carousel {
  z-index: 0;
}

button.slick-arrow {
  width: 1px !important;
}
.slick-initialized .slick-slide {
  display: block;
}

.trendingwrap {
  h2 {
    color: $primary;
    font-size: 3.5rem;

    @media screen and (max-width: 830px) {
      font-size: 1.5rem;
    }

    font-weight: 400;

    font-style: normal;
  }
}

div.trending {
  $panelclr: #d7d5cb;
  $paneltxt: #634521;
  color: darken($panelclr, 40%);

  .product.item {
    border: 1px $panelclr solid;

    &.mega {
      .vm-product-media-container {
        height: 120px;
      }

      img {
        transform: scale(0.8);
        padding: 0px !important;

        transition: transform 350ms cubic-bezier(0.38, 1.25, 0.79, 0.99);
        // transition: 150ms cubic-bezier(0, 0, 0.2, 1);

        &:hover {
          transform: scale(1);
        }
      }

      border: none;
      a {
        &::after {
          display: none;
          content: "";
        }
      }
    }
    .maincont {
      background-color: lighten($panelclr, 15%);
      color: $paneltxt;
      padding-bottom: 50%;

      @media screen and (max-width: 830px) {
        padding-bottom: 72%;
      }
    }

    h3 {
      font-size: 100%;
      word-break: break-word !important;
      display: block;
      height: 60px;

      @media screen and (max-width: 830px) {
        height: 75px;
      }

      background-color: lighten($offblack, 5%);

      a {
        color: #fff;
        margin: auto;
        vertical-align: middle;
        font-weight: 700;
      }

      margin-bottom: 0px;
    }

    .desc {
      font-size: 0.8rem;
      line-height: normal;
    }

    .snippet {
      p {
        margin: 0px;
      }
    }

    .product-price {
      .PricepriceWithoutTaxTt {
        font-size: 2rem;
        color: $primary;
        font-weight: 700;
      }
    }

    .bottomblock {
    }
  }

  .product.item {
  }

  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;

    .ribbon {
      font: bold 13px sans-serif;
      color: #333;
      text-align: center;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      position: relative;
      padding: 7px 0;
      top: 15px;
      left: -30px;
      width: 120px;
      background-color: #85c728;
      color: #fff;
    }
  }
}

.trendingwrapnew {

    h2 {
color: lighten($offblack,30%);
        font-size: 2rem;
        font-weight: 300;
        letter-spacing: -0.1rem;

        @media screen and (max-width: 830px) {
            font-size: 2rem;
            ;
        }



        font-style: normal;
    }
}

div.trendingnew {
    $panelclr: #d7d5cb;
    $paneltxt: #634521;

    color: darken($panelclr, 40%);

    .product.item {
      border: 1px #f2f2f0 solid;
      // @include radius;

      .maincont {
        // background-color: lighten($panelclr, 15%);
        color: $paneltxt;
      }

      .vm-product-media-container {
        height: 200px;
        @media screen and (max-width: 830px) {
            height: 100px;
          }
        a {
          align-items: center !important;

          display: flex;
          width: 100%;
          height: 100%;
          position: relative;
          justify-content: center !important;
          &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(143, 143, 143) !important;
            opacity: 0.08;
            pointer-events: none;
            // border: #131313 solid 1px !important ;
          }
          &:hover::after {
            background: rgb(0, 0, 0) !important;
          }
        }
      }

      &.mega {
        .vm-product-media-container {
          height: 120px;
        }

        img {
          transform: scale(0.8);
          padding: 0px !important;

          transition: transform 350ms cubic-bezier(0.38, 1.25, 0.79, 0.99);
          // transition: 150ms cubic-bezier(0, 0, 0.2, 1);

          &:hover {
            transform: scale(1);
          }
        }

        border: none;
        a {
          &::after {
            display: none;
            content: "";
          }
        }
      }

      h3 {
        font-size: 100%;
        word-break: break-word !important;
        display: block;

        @media screen and (max-width: 830px) {
          // height: 75px;
        }

        a {
          margin: auto;
          vertical-align: middle;
          color: rgba($compliment,0.5);
          font-size: 0.9rem;
        }

        margin-bottom: 0px;
      }

      .desc {
        font-size: 0.8rem;
        line-height: normal;
      }

      .snippet {
        p {
          margin: 0px;
        }
      }

      .product-price {
        .PricepriceWithoutTaxTt {
          font-size: 1.5rem;
          // color: $orange;
          color: lighten($offblack, 60%);
          font-weight: 500;
        }
      }

      .bottomblock {
      }
    }

    .product.item {
    }

    .ribbon-wrapper {
      width: 85px;
      height: 88px;
      overflow: hidden;
      position: absolute;
      top: -3px;
      left: -3px;

      .ribbon {
        font: bold 13px sans-serif;
        color: #333;
        text-align: center;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        position: relative;
        padding: 7px 0;
        top: 15px;
        left: -30px;
        width: 120px;
        background-color: #85c728;
        color: #fff;
      }
    }
  }